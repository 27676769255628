import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { uploadPropertyPoints, uploadPropertyShapes, uploadPropertyStations } from '../../api/asset';
import { uploadStationReadings } from '../../api/asset/uploadStationReadings';

interface FileUploadProps {
  account_id: number;
  property_id?: number;
  variant: number;
}

const FileUpload = (props: FileUploadProps) => {
 
  const accountId = useRef(0);
  const propertyId = useRef(0);

  const onDrop = useCallback((acceptedFiles: any) => {
    console.log(acceptedFiles);
    const formData = new FormData();
    formData.append('account_id', accountId.current.toString());
    if (propertyId.current) 
        formData.append('property_id',  propertyId.current.toString());
    acceptedFiles.forEach((file: any) => formData.append('file', file));
    console.log(props);
    if (props.variant === 1) {
    uploadPropertyPoints(formData)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => console.log(error));
    } else if (props.variant === 2) {
      uploadPropertyStations(formData)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => console.log(error));
    } else if (props.variant === 3) {
      uploadStationReadings(formData)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => console.log(error));
    } else if (props.variant === 4) {
      uploadPropertyShapes(formData)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => console.log(error));
    }
  }, []);

  useEffect(() => {
    console.log(props);
    accountId.current = props.account_id;
    propertyId.current = props.property_id ? props.property_id : 0;
    console.log(accountId.current, propertyId.current);
  }, [props]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()} className='container'>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the files here ...</p>
      ) : (
        <p>Drag and drop files here or click to browse.</p>
      )}
    </div>
  );
};
export default FileUpload;

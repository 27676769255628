import axios from 'axios';
import { newAbortSignal } from '../../utils';
import { DeleteAllRequest } from '../../types/asset/DeleteAllRequest';
import { GenericResponse } from '../../types';
import { SessionStore } from '../../types/store/SessionStore';

const BASE_URL = `${process.env.REACT_APP_BACKEND_URL}/asset/deleteAll`;

export const deleteAll = async ({
  account,
}: DeleteAllRequest): Promise<GenericResponse> => {
  const session: SessionStore | undefined = JSON.parse(
    localStorage.getItem('session') || "{}"
  );

  return await axios
    .post<GenericResponse>(
      BASE_URL,
      {
        account,
      },
      {
        headers: {
          'content-type': 'application/json',
          'x-access-token': session?.accessToken,
        },
        timeout: parseInt(`${process.env.REACT_APP_AXIOS_TIME_OUT}`),
        signal:
          typeof AbortSignal.timeout !== 'undefined'
            ? AbortSignal.timeout(
                parseInt(`${process.env.REACT_APP_AXIOS_TIME_OUT}`)
              )
            : newAbortSignal(
                parseInt(`${process.env.REACT_APP_AXIOS_TIME_OUT}`)
              ),
      }
    )
    .then((response) => response.data);
};

import axios from 'axios';
import { newAbortSignal } from '../../utils';
import { SessionStore } from '../../types/store/SessionStore';
import { CreateAccountRequest } from '../../types/account';

const BASE_URL = `${process.env.REACT_APP_BACKEND_URL}/account`;

export const createAccount = async (createInfo: CreateAccountRequest ): Promise<any> => {
  const session: SessionStore | undefined = JSON.parse(
    localStorage.getItem('session') || '{}'
  );

  const finalURL = BASE_URL +  '/';

  return await axios
    .post<any>(finalURL, createInfo ,{
      headers: {
        'content-type': 'application/json',
        'x-access-token': session?.accessToken,
      },
      timeout: parseInt(`${process.env.REACT_APP_AXIOS_TIME_OUT}`),
      signal:
        typeof AbortSignal.timeout !== 'undefined'
          ? AbortSignal.timeout(
              parseInt(`${process.env.REACT_APP_AXIOS_TIME_OUT}`)
            )
          : newAbortSignal(parseInt(`${process.env.REACT_APP_AXIOS_TIME_OUT}`)),
    })
    .then((response) => response.data);
};

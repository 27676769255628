import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import type { FC, ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../store/UI';
import { createAccount, getAccounts } from '../../api/account';

const Accounts: FC = (): ReactElement => {
  const dispatch = useDispatch();
  const [accountList, setAccountList] = useState<any[] | undefined>(undefined);
  const [newAccount, setNewAccount] = useState<boolean>(false);
  const [accountName, setAccountName] = useState<string | undefined>(undefined);
  const [userName, setUserName] = useState<string | undefined>(undefined);
  const [userEmail, setUserEmail] = useState<string | undefined>(undefined);

  const callGetAccounts = () => {
    dispatch(setLoading(true));
    getAccounts().then((response) => {
      console.log(response);
      setAccountList(response);
      dispatch(setLoading(false));
    });
  };

  const handleNewAccountSubmit = (event: any) => {
    event.preventDefault();
    if (!accountName || !userName || !userEmail) return;
    dispatch(setLoading(true));
    createAccount({
      accountName: accountName,
      userName: userName,
      userEmail: userEmail,
    })
      .then((response) => {
        console.log(response);
        callGetAccounts();
      })
      .catch((error) => {
        dispatch(setLoading(false));
        console.log(error);
      });
  };

  useEffect(() => {
    callGetAccounts();
  }, []);

  return (
    <Container className='mt-4'>
      <Grid container>
        <Grid item xs={3}>
          <Box>
            <Button disabled={newAccount} onClick={() => setNewAccount(true)}>
              Add Account
            </Button>
          </Box>
          {accountList &&
            accountList.map((item, index) => {
              return (
                <Button
                  key={'button-account-' + index}
                  sx={{
                    marginTop: 2,
                    width: '100%',
                  }}
                  variant='contained'
                  onClick={() => console.log('here')}
                >
                  {item.name}
                </Button>
              );
            })}
        </Grid>
        <Grid item xs={9} sx={{}}>
          <Box className='ml-4'>
            {newAccount && (
              <form onSubmit={handleNewAccountSubmit}>
                <Typography>New account</Typography>
                <Box className='mt-2'>
                  <TextField
                    required={true}
                    label='Company Name'
                    id='outlined-start-adornment'
                    sx={{ width: '100%' }}
                    onInput={(e) =>
                      setAccountName((e.target as HTMLInputElement).value)
                    }
                  />
                </Box>
                <Box className='mt-2'>
                  <TextField
                    required={true}
                    label='User Name'
                    id='outlined-start-adornment'
                    className='pr-2'
                    sx={{ width: '50%' }}
                    onInput={(e) =>
                      setUserName((e.target as HTMLInputElement).value)
                    }
                  />
                  <TextField
                    type='email'
                    required={true}
                    label='Email'
                    id='outlined-start-adornment'
                    className='pl-2'
                    sx={{ width: '50%' }}
                    onInput={(e) =>
                      setUserEmail((e.target as HTMLInputElement).value)
                    }
                  />
                </Box>
                <Button type='submit'>Submit</Button>
                <Button
                  onClick={(e) => setNewAccount(false)}
                  className='ml-6'
                  type='button'
                  color='warning'
                >
                  Cancel
                </Button>
              </form>
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Accounts;

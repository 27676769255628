import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationEN from './en.json';
import translationPT from './pt.json';

const resources = {
  en: {
    translation: translationEN
  },
  pt: {
    translation: translationPT
  }
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem('language')
      ? `${localStorage.getItem('language')}`
      : 'en',
    fallbackLng: 'en',
    supportedLngs: ['en', 'pt'],
    load: 'languageOnly',
    lowerCaseLng: true,
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;

import { useTranslation } from 'react-i18next';
import { LanguageEnum } from '../../i18n/LanguageEnum';

export function useTranslationHook(): {
  changeLanguage: (param: LanguageEnum) => void;
  t: (param: string) => string;
} {
  const { t, i18n } = useTranslation();
  const changeLanguage = (language: LanguageEnum) => {
    if (Object.values(LanguageEnum).includes(language)) {
      i18n.changeLanguage(language);
      localStorage.setItem('language', language);
    } else {
      i18n.changeLanguage('en');
      localStorage.setItem('language', 'en');
    }
  };

  const trans = (sentence: string) => {
    return t(sentence);
  };
  return { changeLanguage: changeLanguage, t: trans };
}
import React from 'react';
import { useSelector } from 'react-redux';
import { SessionStore } from '../../types/store/SessionStore';
import { StoreInterfaces } from '../../types/store/StoreInterfaces';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';

const Footer = () => {
  const sessionInfo: SessionStore = useSelector(
    (state: StoreInterfaces) => state.session
  );

  const isLoading: boolean = useSelector(
    (state: StoreInterfaces) => state.ui.loading
  );

  return (
    <>
      <Grid container alignItems={'center'}>
        <Grid item xs={1} />
        <Grid item xs={4}>
          <Typography>
            {sessionInfo.accessToken && `${sessionInfo.name}`}
          </Typography>
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={1} sx={{ alignContent: 'right' }}>
          {isLoading && (
            <CircularProgress size={'2rem'} sx={{}} className='text-white' />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Footer;

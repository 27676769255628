import React from 'react';
import { useTranslationHook } from '../../hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import AdbIcon from '@mui/icons-material/Adb';
import MenuIcon from '@mui/icons-material/Menu';
import LoginIcon from '@mui/icons-material/Login';
import logo from '../../logo.svg';
import { SessionStore } from '../../types/store/SessionStore';
import { StoreInterfaces } from '../../types/store/StoreInterfaces';
import { logout } from '../../utils';

const pages = ['Assets', 'Data', 'Charts'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const Header = () => {
  const { t } = useTranslationHook();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sessionInfo: SessionStore = useSelector(
    (state: StoreInterfaces) => state.session
  );

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleUserSettingClick = (setting: string) => {
    switch (setting.toLowerCase()) {
      case 'logout':
        logout(dispatch);
        navigate('/');
        break;
      default:
        break;
    }
  }

  const MENU_ASSETS = {
    page: 'assets',
    label: 'assets'
  }

  const MENU_DATA = {
    page: 'data',
    label: 'data'
  }

  const MENU_CHARTS = {
    page: 'charts',
    label: 'charts'
  }

  const MENU_ACCOUNTS = {
    page: 'accounts',
    label: 'accounts'
  }

  const defineMenuOptions = () => {
    let OPTIONS = [];
    if (sessionInfo.accessToken) {
      OPTIONS.push(MENU_ASSETS);
      OPTIONS.push(MENU_CHARTS);
    } 
    if (sessionInfo.accessToken && sessionInfo.isAdministrator) {
      OPTIONS.push(MENU_DATA);
      OPTIONS.push(MENU_ACCOUNTS);
    }
    return OPTIONS;

  }

  const handleNavigation = (page: string) => {
    navigate('/' + page.toLowerCase());
  };

  const handleLogin = () => {
    navigate('/login');
  };

  const handleHome = () => {
    navigate('/');
  };

  return (
    <>
      <AppBar position='static' className="bg-primary">
        <Container maxWidth='xl' className='bg-primary'>
          <Toolbar disableGutters>
            <AdbIcon
              onClick={handleHome}
              sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}
            />
            <Typography
              variant='h6'
              noWrap
              component='a'
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              LOGO
            </Typography>
            <Box className="bg-primary" sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size='large'
                aria-label='account of current user'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                onClick={handleHome}
                color='inherit'
              >
                <MenuIcon />
              </IconButton>
              {sessionInfo.accessToken && (
                <Menu
                  id='menu-appbar'
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                >
                  {pages.map((page) => (
                    <MenuItem key={page} onClick={() => handleNavigation(page)}>
                      <Typography textAlign='center'>{page}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              )}
            </Box>
            <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
            <Typography
              variant='h5'
              noWrap
              component='a'
              href='#app-bar-with-responsive-menu'
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              LOGO
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {defineMenuOptions().map((option) => (
                <Button
                  key={option.page}
                  onClick={() => handleNavigation(option.page)}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  {option.label}
                </Button>
              ))}
            </Box>
            {!sessionInfo.accessToken && (
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title='Open settings'>
                  <IconButton
                    className='text-white'
                    onClick={handleLogin}
                    sx={{ p: 0 }}
                  >
                    <LoginIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            {sessionInfo.accessToken && (
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title='Open settings'>
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                      alt='Remy Sharp'
                      src='/static/images/avatar/2.jpg'
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id='menu-appbar'
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                      <Typography onClick={() => handleUserSettingClick(setting)} textAlign='center' component='span'>{setting}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default Header;

import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Layout } from '../components';
import { Accounts, LandingPage, Login } from '../pages';
import { SessionStore } from '../types/store/SessionStore';
import { StoreInterfaces } from '../types/store/StoreInterfaces';
import Data from '../pages/Data';
import { Assets } from '../pages/Assets';
import Charts from '../pages/Charts';

const PrivateRoutes = () => {
  const accessToken: SessionStore['accessToken'] = useSelector(
    (state: StoreInterfaces) => state.session.accessToken
  );
  return accessToken ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to='/' />
  );
};

const PublicRoutes = () => {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

const Router = () => {
  return (
    <Routes>
      <Route element={<PrivateRoutes />}>
        <Route path='/assets' element={<Assets />} />
        <Route path='/data' element={<Data />} />
        <Route path='/charts' element={<Charts />} />
        <Route path='/accounts' element={<Accounts />} />
        <Route path='/aa' element={<LandingPage />} />
      </Route>
      <Route element={<PublicRoutes />}>
        <Route path='/' element={<LandingPage />} />
        <Route path='/login' element={<Login />} />
      </Route>
    </Routes>
  );
};

export default Router;

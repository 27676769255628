import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SessionStore } from '../types/store/SessionStore';
import { SignInResponse } from '../types';

const initialState: SessionStore = JSON.parse(localStorage.getItem("session") || "{}");

const Session = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSessionSignin: (state, action: PayloadAction<SignInResponse>) => {
      state.accessToken = action.payload.accessToken;
      state.isAdministrator = action.payload.isAdministrator;
      state.email = action.payload.email;
      state.name = action.payload.name;
      state.profiles = action.payload.profiles;
      state.roles = action.payload.roles;
      state.sessionId = action.payload.sessionId;
      state.userId = action.payload.userId;
      localStorage.setItem('session', JSON.stringify(state));
    },
    resetSessionAuth: (state) => {
      state.accessToken = undefined;
      localStorage.removeItem('session');
    },
  },
});

export const { setSessionSignin, resetSessionAuth } = Session.actions;

export default Session.reducer;

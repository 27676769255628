import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import Container from '@mui/material/Container';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import Link from '@mui/material/Link';
import { signin } from '../../api/signin';
import { setSessionSignin } from '../../store/Session';
import { setLoading } from '../../store/UI';

const Login = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = (event: any) => {
    dispatch(setLoading(true));
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    signin({
      email: data.get('email')!.toString(),
      password: data.get('password')!.toString(),
    })
      .then((response) => {
        dispatch(setSessionSignin(response));
        dispatch(setLoading(false));
        navigate('/');
      })
      .catch((error) => {
        dispatch(setLoading(false));
        console.log(error);
      });
  };

  return (
    <div className='mt-10 align-middle'>
      <Container component='main' maxWidth='xs'>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component='h1' variant='h5'>
            {t('login.title')}
          </Typography>
          <Box
            component='form'
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin='normal'
              required
              fullWidth
              id='email'
              label={t('login.emailAddress')}
              name='email'
              autoComplete='email'
              autoFocus
            />
            <TextField
              margin='normal'
              required
              fullWidth
              name='password'
              label={t('login.password')}
              type='password'
              id='password'
              autoComplete='current-password'
            />
            <FormControlLabel
              control={<Checkbox value='remember' color='primary' />}
              label={t('login.rememberMe')}
            />
            <Button
              className='bg-primary'
              type='submit'
              fullWidth
              variant='contained'
              sx={{ mt: 3, mb: 2 }}
            >
              {t('login.signIn')}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href='#' variant='body2'>
                  {t('login.forgotPassword')}
                </Link>
              </Grid>
              <Grid item>
                <Link href='#' variant='body2'>
                  {t('login.signUp')}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default Login;

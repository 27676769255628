import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import type { FC, ReactElement } from 'react';

import {
  getAccountProperties,
  getMeasures,
  getPropertyStations,
  getStationMeasureTypes,
  getStationVariableTypes,
} from '../../api/asset';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  Colors,
} from 'chart.js';
import { Line, Scatter } from 'react-chartjs-2';
import { setLoading } from '../../store/UI';
import { useDispatch } from 'react-redux';
import { VariableTypesRequest } from '../../types/asset/VariableTypesRequest';
import { MeasureTypesRequest } from '../../types/asset/MeasureTypesRequest';
import { MeasureRequest } from '../../types/asset/MeasureRequest';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Colors
);

const Charts: FC = (): ReactElement => {
  const dispatch = useDispatch();

  const accountId = useRef<number>(2);
  const [propertyId, setPropertyId] = useState<number>(0);
  const [listAccountProperties, setListAccountProperties] = useState<any[]>([]);
  const [listPropertyStations, setListPropertyStations] = useState<any[]>([]);
  const [listStationVariableTypes, setListStationVariableTypes] = useState<
    any[]
  >([]);
  const [listStationMeasureTypes, setListStationMeasureTypes] = useState<any[]>(
    []
  );
  const [listStationMeasures, setListStationMeasures] = useState<any[]>([]);
  const [chartOptions, setChartOptions] = useState<any | undefined>(undefined);
  const [chartData, setChartData] = useState<any | undefined>(undefined);
  const [selectedStations, setSelectedStations] = useState<number[]>([]);
  const [selectedMeasuresTypes, setSelectedMeasuresTypes] = useState<number[]>(
    []
  );
  const [selectedVariableTypes, setSelectedVariableTypes] = useState<number[]>(
    []
  );
  const [listChartDisplays, setListChartDisplays] = useState<any[]>([]);

  const callGetAccountProperties = () => {
    dispatch(setLoading(true));
    getAccountProperties(accountId.current)
      .then((response) => {
        setListAccountProperties(response);
        dispatch(setLoading(false));
      })
      .catch((error) => {
        dispatch(setLoading(false));
        console.log(error);
      });
  };

  const callGetPropertyStations = () => {
    dispatch(setLoading(true));
    getPropertyStations(accountId.current, propertyId)
      .then((response) => {
        setListPropertyStations(response);
        dispatch(setLoading(false));
      })
      .catch((error) => {
        dispatch(setLoading(false));
        console.log(error);
      });
  };

  const callStationVariableTypes = () => {
    dispatch(setLoading(true));
    const request: VariableTypesRequest = {
      account_id: accountId.current,
      property_id: propertyId,
      stations: selectedStations,
    };
    getStationVariableTypes(accountId.current, propertyId, request)
      .then((response) => {
        setListStationVariableTypes(response);
        dispatch(setLoading(false));
      })
      .catch((error) => {
        dispatch(setLoading(false));
        setListStationVariableTypes([]);
      });
  };

  const callStationMeasureTypes = () => {
    dispatch(setLoading(true));
    const request: MeasureTypesRequest = {
      account_id: accountId.current,
      property_id: propertyId,
      stations: selectedStations,
    };
    getStationMeasureTypes(accountId.current, propertyId, request)
      .then((response) => {
        dispatch(setLoading(false));
        setListStationMeasureTypes(response);
      })
      .catch((error) => {
        console.log(error);
        dispatch(setLoading(false));
        setListStationMeasureTypes([]);
      });
  };

  const callGetMeasures = () => {
    dispatch(setLoading(true));
    const request: MeasureRequest = {
      account_id: accountId.current,
      property_id: propertyId,
      stations: selectedStations,
      variableTypeIds: selectedVariableTypes,
      measureTypeIds: selectedMeasuresTypes,
    };
    getMeasures(accountId.current, propertyId, request)
      .then((response) => {
        setListStationMeasures(response);
        dispatch(setLoading(false));
      })
      .catch((error) => {
        dispatch(setLoading(false));
        setListStationMeasures([]);
      });
  };

  const changeProperty = (property_id: number) => {
    setPropertyId(property_id);
    setSelectedStations([]);
    setListStationMeasureTypes([]);
    setListStationVariableTypes([]);

  };

  const buildChartData = (chart: any, index: number): ChartData<'line'> => {
    const chartData: ChartData<'line'> = {
      labels: undefined,
      datasets: [],
    };
    const filteredMeasuresDatasets = listStationMeasures.filter(
      (item) =>
        item.measure_type_id === chart.measure_type_id &&
        item.variable_type_id === chart.variable_type_id
    );
    console.log(filteredMeasuresDatasets);
    let labelMap: any[] = [];
    filteredMeasuresDatasets.forEach((dataset) => {
      dataset.measures.forEach((item: any) => {
        labelMap.push(item.measured_on);
      });
    });
    const uniqueLabelMap = labelMap.filter(
      (label, index, array) =>
        index === array.findIndex((e: any) => e === label)
    );
    chartData.labels = uniqueLabelMap;
    filteredMeasuresDatasets.forEach((dataset) => {
      chartData.datasets.push({
        label: getStationName(dataset.station_id),
        data: dataset.measures.map((measure: any) => {
          return measure.measure;
        }),
        showLine: false,
        fill: undefined,
        backgroundColor: undefined
      });
    });
    return chartData;
  };

  const getMeasureName = (measure_type_id: number) => {
    const measure = listStationMeasureTypes.findIndex(
      (item) => item.measure_type_id === measure_type_id
    );
    return measure === -1 ? undefined : listStationMeasureTypes[measure].name;
  };

  const getVariableName = (variable_type_id: number) => {
    const variable = listStationVariableTypes.findIndex(
      (item) => item.variable_type_id === variable_type_id
    );
    return variable === -1
      ? undefined
      : listStationVariableTypes[variable].name;
  };

  const getStationName = (station_id: number) => {
    const station = listPropertyStations.findIndex(
      (item) => item.station_id === station_id
    );
    return station === -1 ? undefined : listPropertyStations[station].name;
  };

  const buildChartOptions = (chart: any, index: number) => {
    const chartTitle =
      getMeasureName(chart.measure_type_id) +
      ' - ' +
      getVariableName(chart.variable_type_id);
    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top' as const,
        },
        title: {
          display: true,
          text: chartTitle,
        },
        colors: {
          forceOverride: true,
        },
      },
    };
    return options;
  };

  const handleChangeStationSelected = (
    event: React.SyntheticEvent<Element, Event>,
    checked: boolean,
    index: number
  ) => {
    if (checked) {
      if (selectedStations.indexOf(index) === -1)
        setSelectedStations([...selectedStations, index]);
    } else {
      if (selectedStations.indexOf(index) !== -1) {
        const newSelected = selectedStations.filter((item) => item !== index);
        setSelectedStations(newSelected);
      }
    }
  };

  const handleChangeMeasureTypeSelected = (
    event: React.SyntheticEvent<Element, Event>,
    checked: boolean,
    measure_type_id: number
  ) => {
    if (checked) {
      if (selectedMeasuresTypes.indexOf(measure_type_id) === -1)
        setSelectedMeasuresTypes([...selectedMeasuresTypes, measure_type_id]);
    } else {
      if (selectedMeasuresTypes.indexOf(measure_type_id) !== -1) {
        const newSelected = selectedMeasuresTypes.filter(
          (item) => item !== measure_type_id
        );
        setSelectedMeasuresTypes(newSelected);
      }
    }
  };

  const handleChangeVariableTypeSelected = (
    event: React.SyntheticEvent<Element, Event>,
    checked: boolean,
    index: number
  ) => {
    if (checked) {
      if (selectedVariableTypes.indexOf(index) === -1)
        setSelectedVariableTypes([...selectedVariableTypes, index]);
    } else {
      if (selectedVariableTypes.indexOf(index) !== -1) {
        const newSelected = selectedVariableTypes.filter(
          (item) => item !== index
        );
        setSelectedVariableTypes(newSelected);
      }
    }
  };

  /**
   * EFFECTS
   */

  useEffect(() => {
    setChartOptions(undefined);
    setChartData(undefined);
    if (selectedStations.length > 0) {
      callStationVariableTypes();
      callStationMeasureTypes();
      if (selectedMeasuresTypes.length > 0 && selectedVariableTypes.length > 0) 
        callGetMeasures();  
    } else {
      setListStationMeasureTypes([]);
      setListStationVariableTypes([]);
      setListChartDisplays([]);
    }
  }, [selectedStations]);

  useEffect(() => {
    if (listAccountProperties && listAccountProperties.length > 0) {
      accountId.current = listAccountProperties[0].account_id;
      setPropertyId(listAccountProperties[0].property_id);
      callGetPropertyStations();
    }
  }, [listAccountProperties]);

  useEffect(() => {}, [listStationMeasureTypes, listStationVariableTypes]);

  useEffect(() => {
    console.log(selectedMeasuresTypes, selectedVariableTypes);
    if (selectedMeasuresTypes.length > 0 && selectedVariableTypes.length > 0) {
      setListStationMeasures([]);
      callGetMeasures();
    } else {
      setListStationMeasures([]);
      setListChartDisplays([]);
    }
  }, [selectedMeasuresTypes, selectedVariableTypes]);

  useEffect(() => {
    if (propertyId > 0) callGetPropertyStations();
  }, [propertyId]);

  useEffect(() => {
    callGetAccountProperties();
  }, []);

  useEffect(() => {
    if (listStationMeasures && listStationMeasures.length > 0) {
      const result = listStationMeasures
        .filter(
          (value, index, array) =>
            index ===
            array.findIndex(
              (e) =>
                e.variable_type_id === value.variable_type_id &&
                e.measure_type_id === value.measure_type_id
            )
        )
        .map((item) => ({
          variable_type_id: item.variable_type_id,
          measure_type_id: item.measure_type_id,
        }));
      setListChartDisplays(result);
    }
  }, [listStationMeasures]);

  return (
    <Container
      maxWidth='xl'
      className='mt-4'
      sx={{ heigth: '100%', width: '100%', overflow: 'auto' }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Container>
              <TextField
                sx={{ mt: 2 }}
                id='Account id'
                label='Account Id'
                variant='standard'
                type='number'
                value={accountId.current}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  accountId.current = Number.parseInt(event.target.value);
                }}
              />
            </Container>
            <Container>
              <TextField
                sx={{ mt: 2 }}
                id='Property id'
                label='Property Id'
                variant='standard'
                type='number'
                value={propertyId}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  console.log(event);
                  setPropertyId(Number.parseInt(event.target.value));
                }}
              />
            </Container>
            <Container>
              {listAccountProperties &&
                listAccountProperties.map((item, index) => {
                  return (
                    <Button
                      key={'button-property-' + index}
                      sx={{
                        marginTop: 2,
                        width: '100%',
                      }}
                      variant='contained'
                      onClick={() => changeProperty(item.property_id)}
                    >
                      {item.name}
                    </Button>
                  );
                })}
            </Container>
            <Container
              sx={{
                height: '250px',
                overflow: 'auto',
              }}
            >
              <FormControl>
                {listPropertyStations.map((item, index) => {
                  return (
                    <FormControlLabel
                      key={'option-station-' + index}
                      control={<Checkbox />}
                      label={item.name}
                      checked={selectedStations.findIndex((v) => v === item.station_id) !== -1}
                      onChange={(event, checked) => {
                        handleChangeStationSelected(
                          event,
                          checked,
                          item.station_id
                        );
                      }}
                    />
                  );
                })}
              </FormControl>
            </Container>
          </Grid>
          <Grid item xs={8} className=''>
            <Container>
              <FormControl>
                <FormLabel id='radio-buttons-measures-label'>
                  Measure types
                </FormLabel>
                <FormGroup row aria-labelledby='radio-buttons-measures-label'>
                  {listStationMeasureTypes.map((item, index) => {
                    return (
                      <FormControlLabel
                        key={'option-measure-' + index}
                        value={item.measure_type_id}
                        control={<Checkbox />}
                        label={item.name}
                        checked={selectedMeasuresTypes.findIndex((v) => v === item.measure_type_id) > -1}
                        onChange={(event, checked) => {
                          handleChangeMeasureTypeSelected(
                            event,
                            checked,
                            item.measure_type_id
                          );
                        }}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            </Container>
            <Container>
              <FormControl>
                <FormLabel id='radio-buttons-variable-label'>
                  Variables types
                </FormLabel>
                <FormGroup row aria-labelledby='radio-buttons-variable-label'>
                  {listStationVariableTypes.map((item, index) => {
                    return (
                      <FormControlLabel
                        key={'option-variable-' + index}
                        control={<Checkbox />}
                        label={item.name}
                        onChange={(event, checked) => {
                          handleChangeVariableTypeSelected(
                            event,
                            checked,
                            item.variable_type_id
                          );
                        }}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            </Container>
            {listChartDisplays &&
              listChartDisplays.length > 0 &&
              listChartDisplays.map((chart, index) => {
                const chartData = buildChartData(chart, index);
                const chartOptions = buildChartOptions(chart, index);
                return (
                  <Container key={'container-chart-' + index}>
                    {chartData && chartOptions && (
                      <Line
                        id={'chart-' + index}
                        options={chartOptions}
                        data={chartData}
                      ></Line>
                    )}
                  </Container>
                );
              })}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Charts;

import React from 'react';
import type { FC, ReactElement } from 'react';
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMap
} from 'react-leaflet';
import { renderToStaticMarkup } from "react-dom/server";
import { MdCorporateFare } from "react-icons/md";
import { divIcon } from 'leaflet';

const ComponentResize = () => {
  const map = useMap();

  setTimeout(() => {
    map.invalidateSize();
  }, 0);

  return null;
};

const LandingPage: FC = (): ReactElement => {

  const iconMarkup = renderToStaticMarkup(
    <MdCorporateFare />
  );
  const customMarkerIcon = divIcon({
    html: iconMarkup
  });

  return (
    <div className='h-[100%]'>
      <MapContainer
        className='h-[100%]'
        style={{ width: '100%' }}
        center={[51.505, -0.09]}
        zoom={13}
        scrollWheelZoom={true}
      >
        <ComponentResize />
        <TileLayer
          attribution='Google Maps Satellite'
          url='https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}'
        />
        <Marker position={[51.505, -0.09]} icon={customMarkerIcon}>
          <Popup>
            A pretty CSS3 popup. <br /> Easily customizable.
          </Popup>
        </Marker>
      </MapContainer>
    </div>
  );
};

export default LandingPage;

import React, { ReactNode } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { useSelector } from 'react-redux';
import { StoreInterfaces } from '../../types/store/StoreInterfaces';
import { LinearProgress } from '@mui/material';

interface LayoutProps {
  children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {

  const isLoading: boolean = useSelector(
    (state: StoreInterfaces) => state.ui.loading
  );

  return (
    <div className='flex flex-col justify-between h-[100vh]'>
      <div className='absolute z-10 left-0 right-0'>
        { isLoading && (<LinearProgress />)}
      </div>
      <div>
        <Header />
      </div>
      <div className='mt-2 mb-2 h-[100%]'>{children}</div>
      <div className='h-14 bg-primary text-white'>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;

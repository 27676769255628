import React, { useEffect, useState } from 'react';
import type { FC, ReactElement } from 'react';
import {
  MapContainer,
  Marker,
  Polygon,
  TileLayer,
  useMap,
} from 'react-leaflet';
import L, { LatLngExpression, divIcon } from 'leaflet';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';
import {
  getAccountProperties,
  getPropertyPoints,
  getPropertyPolygon,
  getPropertyStations,
} from '../../api/asset';
import { BiFoodTag } from 'react-icons/bi';
import { PropertyAreaPointIcon, StationLocationIcon, VenueLocationIcon } from '../../components/LeafIcons';
import { Container } from 'postcss';

const ComponentResize = () => {
  const map = useMap();

  setTimeout(() => {
    map.invalidateSize();
  }, 0);

  return null;
};

function SetViewOnCenterChange({ coords }: any) {
  const map = useMap();
  if (coords) map.setView(coords, 13);

  return null;
}

const Assets: FC = (): ReactElement => {
  const [selectedPropertyIndex, setSelectedPropertyIndex] = useState<number>(0);
  const [listProperties, setListProperties] = useState<any[]>([]);
  const [listPropertyPoints, setListPropertyPoints] = useState<any[]>([]);
  const [listPropertyStations, setListPropertyStations] = useState<any[]>([]);
  const [propertyPolygon, setPropertyPolygon] = useState<any[]>([]);
  const [mapCenter, setMapCenter] = useState<LatLngExpression | undefined>(
    undefined
  );

  const callGetPropertyPoints = (accountId: number, propertyId: number) => {
    getPropertyPoints(accountId, propertyId)
      .then((result) => {
        setListPropertyPoints(result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const callGetPropertyStations = (accountId: number, propertyId: number) => {
    getPropertyStations(accountId, propertyId)
      .then((result) => {
        setListPropertyStations(result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const callGetPropertyPolygon = (accountId: number, propertyId: number) => {
    getPropertyPolygon(accountId, propertyId)
      .then((result) => {
        setPropertyPolygon(result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const callGetAccountProperties = () => {
    getAccountProperties(2)
      .then((result) => {
        setListProperties(result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangePropertySelected = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedPropertyIndex(Number.parseInt(event.target.value));
  };

  useEffect(() => {
    callGetAccountProperties();
  }, []);

  useEffect(() => {
    if (listProperties && listProperties.length > 0) {
      setMapCenter({
        lng: listProperties[selectedPropertyIndex].geography.coordinates[0],
        lat: listProperties[selectedPropertyIndex].geography.coordinates[1],
      });
      callGetPropertyPoints(
        listProperties[selectedPropertyIndex].account_id,
        listProperties[selectedPropertyIndex].property_id
      );
      callGetPropertyStations(
        listProperties[selectedPropertyIndex].account_id,
        listProperties[selectedPropertyIndex].property_id
      );
      callGetPropertyPolygon(
        listProperties[selectedPropertyIndex].account_id,
        listProperties[selectedPropertyIndex].property_id
      );
    }
  }, [listProperties, selectedPropertyIndex]);

  useEffect(() => {
    if (listPropertyPoints && listPropertyPoints.length > 0) {
    }
  }, [listPropertyPoints]);

  return (
    <Grid container spacing={2} sx={{ height: '100%' }}>
      <Grid item md={4}>
        <Box className='ml-5'>
          <FormControl>
            <FormLabel id='radio-buttons-properties-label'>
              Properties
            </FormLabel>
            <RadioGroup
              aria-labelledby='radio-buttons-properties-label'
              name='radio-buttons-properties'
              value={selectedPropertyIndex}
              onChange={handleChangePropertySelected}
            >
              {listProperties.map((item, index) => {
                return (
                  <FormControlLabel
                    value={index}
                    control={<Radio />}
                    label={item.name}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        </Box>
        <Box className='ml-5'>
          {listPropertyStations &&
            listPropertyStations.map((item, index) => {
              return <div>{item.name}</div>;
            })}
        </Box>
      </Grid>
      <Grid item md={8}>
        <Box sx={{ height: '100%' }}>
          <MapContainer
            className='h-[100%]'
            style={{ width: '100%' }}
            center={mapCenter}
            zoom={13}
            scrollWheelZoom={true}
          >
            {listPropertyPoints &&
              listPropertyPoints.map((item, index) => {
                const key =
                  '' + item.account_id + '_' + item.property_id + '_' + index;
                const position: LatLngExpression = {
                  lng: Number.parseFloat(item.property_point.coordinates[0]),
                  lat: Number.parseFloat(item.property_point.coordinates[1]),
                };
                return (
                  <Marker
                    key={key}
                    position={position}
                    icon={PropertyAreaPointIcon}
                  />
                );
              })}
            {listPropertyStations &&
              listPropertyStations.map((item, index) => {
                const key =
                  'S' + item.station_id + '_' + item.property_id + '_'+ index;
                const position: LatLngExpression = {
                  lng: Number.parseFloat(item.geo.coordinates[0]),
                  lat: Number.parseFloat(item.geo.coordinates[1]),
                };
                return (
                  <Marker
                    key={key}
                    position={position}
                    icon={StationLocationIcon}
                  />
                );
              })}
            {propertyPolygon.length > 0 && (
              <Polygon
                positions={propertyPolygon[0].geom.coordinates[0].map(
                  (item: any) => {
                    return {
                      lng: Number.parseFloat(item[0]),
                      lat: Number.parseFloat(item[1]),
                    };
                  }
                )}
              />
            )}
            <ComponentResize />
            <SetViewOnCenterChange coords={mapCenter} />
            <TileLayer
              attribution='Google Maps Satellite'
              url='https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}'
            />
          </MapContainer>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Assets;

import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import type { FC, ReactElement } from 'react';
import { deleteAll, getAccountProperties } from '../../api/asset';
import { FileUpload } from '../../components';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../store/UI';

const Data: FC = (): ReactElement => {
  const dispatch = useDispatch();
  const accountId = useRef<number>(2);
  const [propertyId, setPropertyId] = useState<number>(7);
  const [listAccountProperties, setListAccountProperties] = useState<any[]>([]);

  const callGetAccountProperties = () => {
    dispatch(setLoading(true));
    getAccountProperties(accountId.current)
      .then((response) => {
        setListAccountProperties(response);
        dispatch(setLoading(false));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDeleteAll = () => {
    try {
      deleteAll({ account: accountId.current })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const changeProperty = (property_id: number) => {
    setPropertyId(property_id);
  }

  useEffect(() => {
    if (listAccountProperties && listAccountProperties.length > 0) {
      accountId.current = listAccountProperties[0].account_id;
      setPropertyId(listAccountProperties[0].property_id);
    }
  }, [listAccountProperties]);

  useEffect(() => {
    callGetAccountProperties();
  }, []);

  return (
    <Container maxWidth='lg' className='mt-4'>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Container>
              <Button variant='contained' onClick={() => handleDeleteAll()}>
                Clear Properties
              </Button>
            </Container>
            <Container>
              <TextField
                sx={{ mt: 2 }}
                id='Account id'
                label='Account Id'
                variant='standard'
                type='number'
                value={accountId.current}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  accountId.current = Number.parseInt(event.target.value);
                }}
              />
            </Container>
            <Container>
              <TextField
                sx={{ mt: 2 }}
                id='Property id'
                label='Property Id'
                variant='standard'
                type='number'
                value={propertyId}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  console.log(event);
                  setPropertyId(Number.parseInt(event.target.value));
                }}
              />
            </Container>
            <Container>
              {listAccountProperties &&
                listAccountProperties.map((item, index) => {
                  return (
                    <Button
                      sx={{
                        marginTop:2,
                        width: '100%'
                      }}
                      variant='contained'
                      onClick={() => changeProperty(item.property_id)}
                    >
                      {item.name}
                    </Button>
                  );
                })}
            </Container>
          </Grid>
          <Grid item xs={8} className='h-[200px] bg-secondary'>
            <Container id='upload-1'>
              <Typography
                variant='h6'
                noWrap
                component='a'
                sx={{
                  mr: 2,
                  display: { xs: 'none', md: 'flex' },
                  fontWeight: 700,
                  color: 'inherit',
                  textDecoration: 'none',
                }}
              >
                Upload properties points
              </Typography>
            </Container>
            <Container>
              <FileUpload
                key="fileupload-1"
                account_id={accountId.current}
                property_id={propertyId}
                variant={1}
              />
            </Container>
          </Grid>
        </Grid>
        <Grid container spacing={2} className='mt-2'>
          <Grid item xs={4}></Grid>
          <Grid item xs={8} className='h-[200px] bg-[#aad48e]'>
            <Container id='upload-2'>
              <Typography
                variant='h6'
                noWrap
                component='a'
                sx={{
                  mr: 2,
                  display: { xs: 'none', md: 'flex' },
                  fontWeight: 700,
                  color: 'inherit',
                  textDecoration: 'none',
                }}
              >
                Upload property stations
              </Typography>
            </Container>
            <Container>
              <FileUpload
                key="fileupload-2"
                account_id={accountId.current}
                property_id={propertyId}
                variant={2}
              />
            </Container>
          </Grid>
        </Grid>
        <Grid container spacing={2} className='mt-2'>
          <Grid item xs={4}></Grid>
          <Grid item xs={8} className='h-[200px] bg-[#aad48e]'>
            <Container id='upload-4'>
              <Typography
                variant='h6'
                noWrap
                component='a'
                sx={{
                  mr: 2,
                  display: { xs: 'none', md: 'flex' },
                  fontWeight: 700,
                  color: 'inherit',
                  textDecoration: 'none',
                }}
              >
                Upload property shapes
              </Typography>
            </Container>
            <Container>
              <FileUpload
                key="fileupload-4"
                account_id={accountId.current}
                property_id={propertyId}
                variant={4}
              />
            </Container>
          </Grid>
        </Grid>
        <Grid container spacing={2} className='mt-2'>
          <Grid item xs={4}></Grid>
          <Grid item xs={8} className='h-[200px] bg-[#917c9f]'>
            <Container id='upload-3'>
              <Typography
                variant='h6'
                noWrap
                component='a'
                sx={{
                  mr: 2,
                  display: { xs: 'none', md: 'flex' },
                  fontWeight: 700,
                  color: 'inherit',
                  textDecoration: 'none',
                }}
              >
                Upload Readings
              </Typography>
            </Container>
            <Container>
              <FileUpload
                key="fileupload-3"
                account_id={accountId.current}
                property_id={propertyId}
                variant={3}
              />
            </Container>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Data;

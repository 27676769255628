import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UIStore } from '../types/store/UIStore';

const initialState: UIStore = JSON.parse(localStorage.getItem("ui") || "{}");

const ui = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    }
  },
});

export const { setLoading } = ui.actions;

export default ui.reducer;

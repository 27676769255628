import React from 'react';
import { StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import './App.css';
import Router from './routes';
import 'leaflet/dist/leaflet.css';
import axios from 'axios';
import { logout } from './utils';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      if (error.response && error.response.status === 401) {
        logout(dispatch);
        navigate('/')
      }
      return Promise.reject(error);
    }
  );

  return (
    <StyledEngineProvider injectFirst>
      <CssBaseline /> {/* Normalize the CSS */}
      <Router />
    </StyledEngineProvider>
  );
}

export default App;

import axios from 'axios';
import { newAbortSignal } from '../../utils';
import { SessionStore } from '../../types/store/SessionStore';

const BASE_URL = `${process.env.REACT_APP_BACKEND_URL}/asset/`;

export const getPropertyPolygon = async (
  accountId: number,
  propertyId: number
): Promise<any> => {
  const session: SessionStore | undefined = JSON.parse(
    localStorage.getItem('session') || '{}'
  );

  const finalURL = BASE_URL + accountId + '/property/' + propertyId + '/polygon';

  return await axios
    .get<any>(finalURL, {
      headers: {
        'content-type': 'application/json',
        'x-access-token': session?.accessToken,
      },
      timeout: parseInt(`${process.env.REACT_APP_AXIOS_LAZY_TIME_OUT}`),
      signal:
        typeof AbortSignal.timeout !== 'undefined'
          ? AbortSignal.timeout(
              parseInt(`${process.env.REACT_APP_AXIOS_LAZY_TIME_OUT}`)
            )
          : newAbortSignal(parseInt(`${process.env.REACT_APP_AXIOS_LAZY_TIME_OUT}`)),
    })
    .then((response) => response.data);
};

import axios from 'axios';
import { newAbortSignal } from '../../utils';
import { GenericResponse } from '../../types';
import { SessionStore } from '../../types/store/SessionStore';

const BASE_URL = `${process.env.REACT_APP_BACKEND_URL}/asset/uploadStationReadings`;

export const uploadStationReadings = async (formData: any): Promise<GenericResponse> => {
  
  const session: SessionStore | undefined = JSON.parse(
    localStorage.getItem('session') || '{}'
  );

  return await axios
    .post<GenericResponse>(BASE_URL, formData, {
      headers: {
        'content-type': 'multipart/form-data',
        'x-access-token': session?.accessToken,
      },
      timeout: parseInt(`${process.env.REACT_APP_AXIOS_TIME_OUT}`),
      signal:
        typeof AbortSignal.timeout !== 'undefined'
          ? AbortSignal.timeout(
              parseInt(`${process.env.REACT_APP_AXIOS_TIME_OUT}`)
            )
          : newAbortSignal(parseInt(`${process.env.REACT_APP_AXIOS_TIME_OUT}`)),
    })
    .then((response) => response.data);
};

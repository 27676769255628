import axios from 'axios';
import { newAbortSignal } from '../utils';
import type { SignInRequest, SignInResponse } from '../types';

const BASE_URL = `${process.env.REACT_APP_BACKEND_URL}/auth/signin`;

export const signin = async ({
  email,
  password,
}: SignInRequest): Promise<SignInResponse> => {
  return await axios
    .post<SignInResponse>(
      BASE_URL,
      {
        email: email,
        password: password,
      },
      {
        headers: {
          'content-type': 'application/json',
        },
        timeout: parseInt(`${process.env.REACT_APP_AXIOS_TIME_OUT}`),
        signal:
          typeof AbortSignal.timeout !== 'undefined'
            ? AbortSignal.timeout(
                parseInt(`${process.env.REACT_APP_AXIOS_TIME_OUT}`)
              )
            : newAbortSignal(
                parseInt(`${process.env.REACT_APP_AXIOS_TIME_OUT}`)
              ),
      }
    )
    .then((response) => response.data);
};
